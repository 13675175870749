import { handlePromise, showToast } from "~lib/helpers";
import { sdk } from "~lib";
import { useOrganization } from "~hooks";
import { useRouter } from "next/router";

export default function useGoToRelease(eventId: string) {
  const { organization } = useOrganization();
  const router = useRouter();
  const cartRecovery = !!router.query.cartRecovery;

  const gotToRelease = async (inputId: string) => {
    const inputElement = document.getElementById(inputId) as HTMLInputElement;

    const code = inputElement.value?.trim();
    if (!code) {
      return;
    }

    const { data: release, error } = await handlePromise(async () =>
      sdk({ orgId: organization?.id }).validateReleasePasswordOrRelease({
        eventId,
        password: code,
      })
    );

    if (!release?.validateReleasePasswordOrRelease) {
      showToast("Invalid access code", "error");
      return;
    }

    if (error) {
      showToast("Failed to find access code", "error");
      return;
    }

    if (release?.validateReleasePasswordOrRelease?.releasePasswordId) {
      sessionStorage.setItem(
        "releasePasswordId",
        release?.validateReleasePasswordOrRelease?.releasePasswordId
      );
    }

    void router.push({
      pathname: `/${eventId}/releases/${encodeURIComponent(
        release?.validateReleasePasswordOrRelease.slug
      )}`,
      query: {
        code: release?.validateReleasePasswordOrRelease?.password
          ? undefined
          : code,
        ...(cartRecovery ? { cartRecovery: "true" } : {}),
      },
    });
  };

  return gotToRelease;
}
