/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { pick } from "@styled-system/props";
import {
  Box,
  Flex,
  Link,
  SystemProps,
  Text,
  LegacyText,
  UnderlineButton,
} from "flicket-ui";
import { useState } from "react";
import { ContentModal, Icon, SlateRenderer } from "~components";
import Image from "~components/common/Image";
import { EventQuery } from "~graphql/sdk";
import useDescriptionText from "~hooks/useDescriptionText";
import formatVenue from "../utils/venue";
import { useOrganization } from "~hooks";
import {
  CalendarBlank,
  MapPin,
  Wheelchair,
  IconContext,
} from "@phosphor-icons/react";
import { formatEventDateRange } from "~lib/helpers/formatDate";
import { MoreDatesButton } from "~features/reservation/non-seated/MoreDatesButton";

interface EventInformationProps {
  event: EventQuery["event"];
}

export function EventSummary(props: EventInformationProps) {
  const { event } = props;
  const { endDate, startDate, accessibility, venue } = event;

  const { organization, hasFeature } = useOrganization();
  const [isAccessibilityModalOpen, setAccessibilityModalOpen] = useState(false);

  const calendarView = hasFeature("eventCalendarHomepage");

  return (
    <>
      <IconContext.Provider
        value={{
          size: 24,
          weight: "light",
        }}
      >
        <Flex
          flexDirection={"column"}
          justifyContent="space-between"
          style={{ gap: "16px" }}
        >
          <Flex>
            <Icon icon={<CalendarBlank />} color="N800" />
            <LegacyText
              color="N700"
              fontWeight="regular"
              fontSize={4}
              ml={1}
              as={Flex}
            >
              {formatEventDateRange(startDate, endDate, {
                format: "detailedShort",
                showStartTime: event.showStartTime,
                showEndTime: event.showEndTime,
                timeZone: venue?.timezone,
                locale: venue?.locale,
                doorsOpen: event.dates?.[0].doorsOpen,
                seating: venue?.seating,
              })}

              {calendarView && (
                <MoreDatesButton>
                  <LegacyText
                    color="N700"
                    fontWeight="regular"
                    fontSize={4}
                    ml={1}
                  >
                    More dates
                  </LegacyText>
                </MoreDatesButton>
              )}
            </LegacyText>
          </Flex>
          <Link
            display="flex"
            target="_blank"
            href={`https://www.google.com/maps/search/?api=1&query=${venue?.location?.lat},${venue?.location?.lng}`}
          >
            <Icon icon={<MapPin />} color="N800" />
            <LegacyText color="N700" fontWeight="regular" fontSize={4} ml={1}>
              {formatVenue(venue)}
            </LegacyText>
          </Link>
          {accessibility && (
            <Flex>
              <Icon icon={<Wheelchair />} color="N800" />
              <UnderlineButton
                onClick={() => setAccessibilityModalOpen(true)}
                color="N800"
              >
                <LegacyText
                  color="N700"
                  fontWeight="regular"
                  fontSize={4}
                  ml={1}
                >
                  Accessibility Info
                </LegacyText>
              </UnderlineButton>
            </Flex>
          )}
        </Flex>
      </IconContext.Provider>

      <ContentModal
        isOpen={isAccessibilityModalOpen}
        setIsOpen={() => setAccessibilityModalOpen(false)}
        title="Accessibility"
        richText={event?.accessibility}
      />
    </>
  );
}

export function EventDescription(props: EventInformationProps) {
  const { descriptionImage, description } = props.event;

  const descriptionText = useDescriptionText(description);
  return (
    <Box mt={5}>
      <SlateRenderer value={description} mb={3} />
      {descriptionImage && (
        <Image image={descriptionImage} alt={descriptionText} />
      )}
    </Box>
  );
}

export function TermsAndConditions(props: EventInformationProps) {
  const { organization } = useOrganization();

  const { termsAndConditions, id } = props.event;
  const terms = termsAndConditions ?? organization.termsAndConditions;

  if (!terms) return null;

  return (
    <UnderlineButton
      color="N800"
      href={`/events/${id}/terms-and-conditions`}
      mt={5}
    >
      <Text variant="regular">Terms &amp; Conditions</Text>
    </UnderlineButton>
  );
}

// Event information - does not include the the summary
export default function EventInformation(
  props: EventInformationProps & SystemProps
) {
  return (
    <Box {...pick(props)}>
      <EventDescription {...props} />
      <TermsAndConditions {...props} />
    </Box>
  );
}
